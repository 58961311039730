import { scrollspy, tooltip } from '../js/uikit.js';

let logOn = true;
const console_me = {
  log: (obj) => {
    if (logOn) {
      console.log(obj);
    }
  }
}

const TECH_STACK_LIST = [
  'javascript-img',
  'vue-img',
  'vuetify-img',
  'html5-img',
  'css3-img',
  'jest-img',
  'express-img',
  'python-img',
  'firebase-img',
  'scylladb-img',
  'sqlite-img',
  'linux-img',
  'windows-img',
  'aws-img',
  'nodejs-img',
  'github-img',
  'git-img',
  'heroku-img',
]
let techStackIsActive = false;
window.showAllTStackTooltips = function showAllTStackTooltips() {
  // https://stackoverflow.com/questions/17246275/settimeout-and-array-each

  if (!techStackIsActive) { // prevents subsequent calls
    techStackIsActive = true;

    const TIME_TO_SHOW = 500;
    let offset = 0;

    TECH_STACK_LIST.forEach((name) => {
      setTimeout(() => {
        let elem = document.getElementById(name);
        tooltip(elem).show();
      }, TIME_TO_SHOW + offset);
      offset += TIME_TO_SHOW; 

      // Hides very last element after the tooltip is shown for 500ms
      // Without, the tooltip remains until another tooltip shows
      if (offset === (TIME_TO_SHOW * TECH_STACK_LIST.length)) {
        setTimeout(() => {
          let lastTech = TECH_STACK_LIST[TECH_STACK_LIST.length - 1];
          let elem = document.getElementById(lastTech);
          tooltip(elem).hide();

          techStackIsActive = false;
        }, TIME_TO_SHOW + offset);
      }
    });
  }
}

window.swapAnimeImgToWhite = function swapAnimeImgToWhite() {
  let img_anime = document.getElementById('anime-img');
  let bundledImgFilePath = require('../img/logos/crw.png');
  img_anime.setAttribute('src', bundledImgFilePath);
}
window.swapAnimeImgToBlack = function swapAnimeImgToBlack() {
  let img_anime = document.getElementById('anime-img');
  let bundledImgFilePath = require('../img/logos/crunchyroll-black.png');
  img_anime.setAttribute('src', bundledImgFilePath);
}

const addClass = (elem, className) => {
  // let div_movie = document.getElementById(elem.id);
  const arr_classes = elem.className.split(' ');
  if (arr_classes.indexOf(className) == -1) {
    elem.className += " " + className;
  }
  console.log(`Added ${className} to ${elem.id}: ${elem.className}`);
}

const removeClass = (id, className) => {
  let element = document.getElementById(id);
  const cNameRegex = `\\s?\\b${className}\\b`;
  const regex = new RegExp(cNameRegex, 'g');
  element.className = element.className.replace(regex, '');
  console.log(`Removed ${className} from ${id}: ${element.className}`);
}

// window.displaySlider = (elem) => {
//   const CATEOGORIES = [ 'movies', 'shows', 'sports', 'food', 'music', 'games', 'travel', 'anime' ];

//   // Cleanse animations
//   CATEOGORIES.forEach((category) => {
//     removeClass(category, 'uk-animation-scale-down');
//   });

//   const div_favorites = document.getElementById('favorites');
  
//   if (div_favorites.dataset.activeSlider === 'false' || div_favorites.dataset.activeSlider === undefined) {
//     removeClass(elem.id, 'uk-animation-scale-down');
//     addClass(elem, 'uk-animation-scale-up');

//     // Must consistently mutate .gridArea throughout all code
//     // Mutating a mix of .grid || gridRow || gridCol will lead to unintended effects
//     elem.style.gridArea = `1 / 1 / span 2 / span 1`;

    // // Remove every category except the selected one
    // CATEOGORIES.forEach((category) => {
    //   if (elem.id !== category) {
    //     let e = document.getElementById(category);
    //     e.style.display = 'none';
    //   }
    // });

//     // Create div that contains slider
//     let new_div_grid_slider = document.createElement('div');
//     new_div_grid_slider.style.gridRow = '1 / 3';
//     new_div_grid_slider.style.gridColumn = '2 / 5';
//     new_div_grid_slider.className = 'slider-grid';

//     let new_div_slider = document.createElement('div');
//     new_div_slider.setAttribute('uk-slider', '');
//     new_div_slider.setAttribute('tab-index', '-1');
//     new_div_slider.className = 'uk-position-relative uk-visible-toggle uk-light uk-slider uk-slider-container uk-animation-scale-up';

//     // Create <ul></ul>
//     let new_ul = document.createElement('ul');
//     new_ul.className = 'uk-slider-items uk-grid uk-grid-match';
//     new_ul.setAttribute('uk-height-viewport', 'offset-top: true; offset-bottom: 30');

//     let new_li = document.createElement('li');
//     new_li.className = 'uk-width-3-4';
//     new_li.setAttribute('tab-index', '-1');

//     let new_li_div = document.createElement('div');
//     new_li_div.className = 'uk-cover-container';

//     let new_img = document.createElement('img');
//     let bundledImgFilePath = require('../img/crunchyroll-black.png');
//     new_img.setAttribute('src', bundledImgFilePath);
//     new_img.setAttribute('alt', '');

//     let new_div_slider_panel = document.createElement('div');
//     new_div_slider_panel.className = 'uk-position-center uk-panel';

//     new_li_div.appendChild(new_img);
//     new_li_div.appendChild(new_div_slider_panel);

//     new_li.appendChild(new_li_div);

//     new_ul.appendChild(new_li);
//     new_ul.appendChild(new_li.cloneNode(true));
//     new_ul.appendChild(new_li.cloneNode(true));
//     new_ul.appendChild(new_li.cloneNode(true));
//     new_ul.appendChild(new_li.cloneNode(true));
//     new_ul.appendChild(new_li.cloneNode(true));
//     new_ul.appendChild(new_li.cloneNode(true));

//     new_div_slider.appendChild(new_ul);

//     new_div_grid_slider.append(new_div_slider);

//     div_favorites.appendChild(new_div_grid_slider);
//     div_favorites.dataset.activeSlider = true;

//   } else {
//     removeClass(elem.id, 'uk-animation-scale-up');
//     addClass(elem, 'uk-animation-scale-down');

//     let slider = document.querySelector('.slider-grid');
//     slider.remove();

//     // Must consistently mutate .gridArea throughout all code
//     // Mutating a mix of .grid || gridRow || gridCol will lead to unintended effects 
//     elem.style.gridArea = `${elem.dataset.row} / ${elem.dataset.col} / span 1 / span 1`;

//     // Add back every category except the selected one
//     CATEOGORIES.forEach((category) => {
//       if (elem.id !== category) {
//         let e = document.getElementById(category);
//         e.style.display = 'flex';
//       }
//     });

//     let div_favorites = document.getElementById('favorites');
//     div_favorites.dataset.activeSlider = false;
//   }
// }

window.toggleNav = () => {
  let elem = document.getElementById('nav');

  if (elem.dataset.toggle === 'true') {
    removeClass(elem.id, 'uk-animation-fade');
    elem.style.visibility = 'hidden';
    elem.dataset.toggle = 'false';
  }
  else {
    addClass(elem, 'uk-animation-fade');
    elem.style.visibility = 'visible';
    elem.dataset.toggle = 'true';
  }
}

window.displaySlider = (elem) => {
  // https://stackoverflow.com/questions/1248081/how-to-get-the-browser-viewport-dimensions
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  const CATEOGORIES = [ 'movies', 'shows', 'sports', 'food', 'music', 'games', 'travel', 'anime' ];

  const current_cateogry = elem.id;
  const slider_id = `${current_cateogry}-slider`;
  
  let slider = document.getElementById(slider_id);

  const div_favorites = document.getElementById('favorites');

  // Cleanse animations
  CATEOGORIES.forEach((category) => {
    removeClass(category, 'uk-animation-scale-down');
  });
  
  if (div_favorites.dataset.activeSlider === 'false' || div_favorites.dataset.activeSlider === undefined) {
    slider.style.display = 'block';

    // Must consistently mutate .gridArea throughout all code
    // Mutating a mix of .grid || gridRow || gridCol will lead to unintended effects
    if (vw > 550) {
      elem.style.gridArea = `1 / 1 / span 2 / span 1`;
    } else {
      elem.style.gridArea = `1 / 1 / span 1 / span 4`;
    }

    // Remove every category except the selected one
    CATEOGORIES.forEach((category) => {
      if (current_cateogry !== category) {
        let e = document.getElementById(category);
        e.style.display = 'none';
      }
    });

    // For changing animations
    removeClass(elem.id, 'uk-animation-scale-down');
    addClass(elem, 'uk-animation-scale-up');

    div_favorites.dataset.activeSlider = true;
  } else {
    slider.style.display = 'none';

    // Add back every category except the selected one
    CATEOGORIES.forEach((category) => {
      if (current_cateogry !== category) {
        let e = document.getElementById(category);
        e.style.display = 'flex';
      }
    });

    // Must consistently mutate .gridArea throughout all code
    // Mutating a mix of .grid || gridRow || gridCol will lead to unintended effects 
    elem.style.gridArea = `${elem.dataset.row} / ${elem.dataset.col} / span 1 / span 1`;

    removeClass(elem.id, 'uk-animation-scale-up');
    addClass(elem, 'uk-animation-scale-down');

    div_favorites.dataset.activeSlider = false;
  }
}

window.showMoreProjectInfo = () => {
  let info = document.getElementById('pihp-info-1');
  if (info.style.display === 'none') {
    info.style.display = 'block';
    scrollspy(info); // "activate animation"
  } else {
    info.style.display = 'none';
  }
};

// window.toggleMyNav = () => {
//   let nav = document.getElementById('my-nav');
//   let nav_links = document.querySelectorAll('.my-nav-link');
//   console.log(nav_links);
//   if (nav.dataset.active === 'false') {
//     nav_links[0].style.display = 'inline';
//     nav_links[1].style.display = 'inline';
//     nav_links[2].style.display = 'inline';
//     scrollspy(nav_links[0]);
//     scrollspy(nav_links[1]);
//     scrollspy(nav_links[2]);
//     nav.dataset.active = 'true'
//   } else {
//     nav_links[0].style.display = 'none';
//     nav_links[1].style.display = 'none';
//     nav_links[2].style.display = 'none';
//     nav.dataset.active = 'false';
//   }
// };